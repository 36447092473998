import Component, { mixins } from 'vue-class-component'

import WithRender from './FileUpload.html'

import { 
  MButton,
  MFileUpload,
  MIcon,
  MValidationMessage
} from '@ulaval/modul-components'
import { MFile } from '@ulaval/modul-components/dist/utils/file/file'

import UploadedFileRow from '@/modules/global/file/UploadedFileRow'

import FormElementMixin from '@/modules/form-management/form-element/FormElement.mixin'
import { IFileUploadModel } from '../../../global/file/File.model'
import { Watch } from 'vue-property-decorator'
import { GLOBAL_EVENTS } from '../../../global/utilities/Events'
import { IFormAnswerForm } from '@/modules/form-management/Form.service'
import { IFormAnswerFileModel, IFormAnswerModel } from '@/modules/form-management/Form.model'

@WithRender
@Component({
  components: {
    MButton,
    MFileUpload,
    MIcon,
    MValidationMessage,
    UploadedFileRow
  }
})
export default class FileUpload extends mixins(FormElementMixin) {
  private files: Array<IFileUploadModel<IFormAnswerFileModel>> = []

  private get computedAllowedExtensions(): Array<string> {
    if (!this.assembledElementProps['allowed-extensions']) {
      return []
    }

    return this.assembledElementProps['allowed-extensions'].split(',')
  }

  private get computedMinFileCount(): number {
    return this.elementInternalLogicParameters['minimum-file-count'] || 0
  }

  private get computedMaxFileCount(): number | null {
    return this.assembledElementProps['maxFiles'] || null
  }

  @Watch('formElementAnswer', { immediate: true })
  protected onFormElementAnswerChangeEvent(formAnswer: IFormAnswerModel): void {
    if (formAnswer && formAnswer.form_answer_files) {
      this.files = formAnswer.form_answer_files.map((formAnswerFile: IFormAnswerFileModel) => {
        return {
          server_file: formAnswerFile
        }
      })
    }
  }

  @Watch('attachments')
  private onAttachmentsChange(): void {
    this.$emit(GLOBAL_EVENTS.MODEL_CHANGE, this.files)
  }

  @Watch('files', { immediate: true })
  private onFilesChange(): void {
    if (this.computedMaxFileCount && this.files.length > this.computedMaxFileCount) {
      if (this.computedMaxFileCount > 1) {
        this.errorMessage = this.$i18next.t(
          'modules.form-management.form-custom-element.file-upload.FileUpload.validation.max_count.plural', 
          { count: this.computedMaxFileCount }
        )
      } else {
        this.errorMessage = this.$i18next.t(
          'modules.form-management.form-custom-element.file-upload.FileUpload.validation.max_count.singular', 
          { count: this.computedMaxFileCount }
        )
      }
    } else {
      this.errorMessage = null
    }
  }

  private onFilesReady(files: Array<MFile>): void {
    this.$file.uploadTemp(files)
  }

  private onFilesUploadCompleted(files: Array<MFile>): void {
    files.forEach((file: MFile) => {
      this.$set(this.files, this.files.length, { file })
    })
  }

  private removeAttachment(index: number): void {
    this.$delete(this.files, index)
  }

  public async getElementAnswersRecursive(validateAnswers: boolean = true): Promise<Array<IFormAnswerForm> | null> {
    const answer: IFormAnswerForm = {
        element_position_index: this.rowPositionIndex == null ? undefined : this.rowPositionIndex,
        value: undefined,
        id_form_template_element: this.internalElement.id as any,
        id_form_answer: this.formElementAnswer ? this.formElementAnswer.id : undefined,
        files: []
      }

    this.files.forEach((file: IFileUploadModel<IFormAnswerFileModel>) => {
      if (answer.files) {
        answer.files.push(file)
      }
    }) 

    if (!validateAnswers) {
      return [
        answer
      ]
    }
    
    return this.validate() ? [ answer ] : null
  }

  protected validate(): boolean {
    this.errorMessage = null
    
    if (this.computedMinFileCount === 1 && this.files.length === 0) {
      this.errorMessage = this.$i18next.t('modules.form-management.form-custom-element.file-upload.FileUpload.validation.required')
    } else if (this.files.length < this.computedMinFileCount) {
      this.errorMessage = this.$i18next.t(
        'modules.form-management.form-custom-element.file-upload.FileUpload.validation.min_count', 
        { count: this.computedMinFileCount }
      )
    } else if (this.computedMaxFileCount && this.files.length > this.computedMaxFileCount) {
      if (this.computedMaxFileCount > 1) {
        this.errorMessage = this.$i18next.t(
          'modules.form-management.form-custom-element.file-upload.FileUpload.validation.max_count.plural', 
          { count: this.computedMaxFileCount }
        )
      } else {
        this.errorMessage = this.$i18next.t(
          'modules.form-management.form-custom-element.file-upload.FileUpload.validation.max_count.singular', 
          { count: this.computedMaxFileCount }
        )
      }
    }

    return this.mixin_string_isEmpty(this.errorMessage)
  }
}
